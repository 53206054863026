<template>
  <section>
    <img src="../assets/BlockSeeMore/SeeMore.svg" alt="" class="slope"/>
    <div class="content">
      <h1>Wanna see more ?</h1>
      <BigButton/>
      <span> developped by Jean // designed by Mélissa</span>
    </div>
  </section>
</template>

<script>
import BigButton from './BigButton.vue'

export default {
  components: {
    BigButton
  }
}
</script>

<style lang="scss" scoped>

section {
  h1{
    color: white;
    margin: 50px 0 50px 0;
  }

  .slope{
    width: 100%;
    vertical-align:top;
  }

  .content{
    width: 100%;
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    > span{
      margin: 63px 0 38px 0;
      font-family: "Poppins", sans-serif;
      font-size: 15px;
      color: white;
    }
  }
}

</style>
