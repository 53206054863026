<template>
  <div class="cuve" ref="cuve">
    <img src="../assets/blockCuve/cuve.svg" alt="" class="cuve" />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      class="svg"
      ref="svg"
    >
      <path
        id="original1"
        d="M250,412c-42.1-59.9-83.8-119.2-125.7-178.8c4-2.3,7.8-4.6,11.7-6.8c10.7-6.2,21.4-12.4,32-18.6 c1.6-0.9,3.3-1.8,4.9-2.8c1.2-0.8,1.8-0.6,2.6,0.6c5.3,7.6,10.6,15.2,16,22.8c12.2,17.4,24.4,34.8,36.6,52.2 c6.8,9.7,13.7,19.4,20.6,29.1c0.3,0.4,0.6,0.7,1.2,1.5c1.2-2.9,2.3-5.6,3.3-8.2c8-20.6,15.9-41.2,23.9-61.8 c8.7-22.4,17.5-44.8,26.2-67.2c6.5-16.9,13.1-33.7,19.6-50.6c0.7-1.8,1.3-3.6,2.1-5.3c0.4-0.8,1.1-1.5,1.9-2 c12.6-7.3,25.2-14.6,37.8-21.9c3.6-2.1,7.2-4.2,11.1-6.4C333.8,196,292,303.8,250,412z"
      />
      <path
        id="original2"
        d="M324,107.1c-25.1,64.9-50.1,129.2-75.3,194.3c-25.3-36-50.3-71.5-75.4-107.3c4.1-2.4,8.1-4.7,12-7 c10.9-6.3,21.8-12.6,32.6-18.9c1.7-1,2.6-0.8,3.6,0.7c6.8,9.7,13.8,19.4,20.6,29.1c2.1,2.9,4.1,5.9,6.5,9.4 c1.3-3.2,2.4-6,3.5-8.8c8-20.7,15.9-41.4,23.8-62.1c1.1-3,3.8-3.9,6.1-5.2c13.4-7.8,26.9-15.6,40.4-23.4 C322.8,107.6,323.1,107.5,324,107.1z"
      />
      <path
        id="flutter1"
        style="visibility: hidden"
        d="M337,106.2c-1.5,2.6-2.9,5.3-4.4,7.9c-5.6,9.8-11.3,19.5-17,29.3c-7.8,13.5-15.6,27-23.4,40.5 c-6.2,10.8-12.4,21.6-18.7,32.4c-6.8,11.7-13.6,23.4-20.4,35.2c-6.1,10.6-12.2,21.2-18.3,31.8c-6.2,10.7-12.4,21.4-18.6,32.1 c-6.1,10.6-12.2,21.2-18.3,31.8c-0.2,0.4-0.4,0.9-0.7,1.2c-1.8,2.9-2.2,3-5.2,1.3c-9.3-5.4-18.6-10.8-27.9-16.1 c-1.9-1.1-2.2-2.2-1.1-4.1c9.9-16.9,19.7-33.9,29.5-50.8c7.9-13.7,15.8-27.4,23.7-41.1c9.6-16.5,19.2-33.1,28.7-49.6 c7.2-12.5,14.5-24.9,21.5-37.5c2.4-4.3,5.4-7.6,9.8-10c19.3-10.9,38.5-22.1,57.7-33.1c0.8-0.5,1.7-0.9,2.6-1.4 C336.8,106,336.9,106.1,337,106.2z"
      />
      <path
        id="flutter2"
        style="visibility: hidden"
        d="M337.5,356c-1.4,0.9-2.5,1.7-3.7,2.3c-17.8,10.2-35.6,20.5-53.3,30.7c-2.4,1.4-4.8,2.7-7.1,4.2c-1.7,1.1-3.2,1.2-5,0.2 c-9.3-5.5-18.7-10.8-28.1-16.2c-8.7-5.1-17.5-10.1-26.3-15.1c-1.6-0.9-1.7-1.7-0.9-3.1c6.8-11.6,13.5-23.1,20.2-34.7 c4.4-7.6,8.7-15.2,13.1-22.8c6.7-11.7,13.5-23.3,20.2-35c0.6-1,1.2-2,1.7-3.1c1.5-3.1,4.2-4.9,7.1-6.6c20-11.5,40-23.1,60-34.7 c0.4-0.2,0.8-0.4,1.3-0.6c0.2,0.1,0.4,0.3,0.6,0.4c-19.3,33.4-38.6,66.8-58.2,100.6C298.5,333.6,317.8,344.7,337.5,356z"
      />
      <path
        id="js1"
        style="visibility: hidden"
        d="M400,209.9c-0.5,6.7-0.6,13.5-1.4,20.2c-2.1,16.2-7.9,31.2-16.2,45.2c-8.5,14.3-19.1,26.7-31.8,37.4 c-16,13.5-33.5,24.3-54.2,28.9c-10.2,2.3-20.2,1.8-29.7-3.1c-6.5-3.4-11.3-8.6-15.2-14.8c-1.2-2,0-3.3,1-4.6 c4.4-5.5,8.8-11.1,13.2-16.6c5.9-7.4,11.8-14.7,17.6-22.1c2.1-2.6,2.5-2.6,4.5,0c6,7.7,13.4,11.8,23.4,9.9 c6-1.1,11.7-3.2,16.8-6.5c9.6-6.1,17.8-13.7,23.5-23.8c3.5-6.3,5.3-13.1,5-20.4c-0.3-8.3-4.5-12.9-13.4-13.2 c-8.3-0.3-16.7,0.3-25,0.6c-8,0.3-16,0.7-24-0.7c-14.1-2.5-22.6-10.8-26.2-24.5c-3.3-12.5-2.9-25.2-0.6-37.7 c4.2-22.8,14.4-42.7,29.4-60.3c11.8-13.8,25.8-24.9,42.4-32.5c6.5-3,13.3-5.4,20.5-6c0.4,0,0.7-0.2,1.1-0.4c1.3,0,2.5,0,3.8,0 c0.3,0.1,0.6,0.3,0.9,0.4c8.7,0.8,15.3,5.2,20.7,11.7c3.4,4.1,3.5,5.3,0.3,9.5c-9.2,12.3-18.5,24.6-27.8,36.8 c-2.7,3.5-2.7,3.5-5.4-0.1c-4.5-6-9.9-7.8-17-5.6c-15.1,4.8-27.3,20.2-28.3,35.9c-0.8,11.3,3.8,16.7,15.1,16.8 c6.9,0.1,13.8-0.5,20.8-0.9c9.5-0.5,19.1-0.9,28.5,1.1c7,1.5,13.3,4.3,18,9.9c4.7,5.4,7,11.9,8.2,18.8c0.6,3.3,0.9,6.6,1.4,9.9 C400,209.6,400,209.7,400,209.9z"
      />
      <path
        id="js2"
        style="visibility: hidden"
        d="M229.6,228.8c-0.5,25.7-0.9,51.4-1.3,77.1c-0.2,14.4-1.6,28.6-5.7,42.5c-8.7,29.7-25.9,53.1-51.4,70.6c-10.1,6.9-20.7,12.6-32.8,15c-10.1,2.1-19.8,1.2-28.2-5.5 c-4.1-3.3-7.3-7.3-9.7-12c-0.7-1.4-0.6-2.6,0.4-3.9 c10-12.7,19.9-25.5,29.8-38.3c0.9-1.1,1.7-2.2,2.6-3.3c1-1.3,1.8-1.2,2.7,0.2c1.7,2.5,3.4,5,5.2,7.2c3.8,4.5,8.7,5.2,14.1,4 c5.6-1.3,10.1-4.6,14.3-8.3c11.7-10.3,15.1-23.6,15.2-38.6c0.2-23.6,0.9-47.3,1.3-70.9c0.3-17,0.6-34,0.9-51 c0.3-16.3,0.6-32.6,0.9-48.9c0-2.5,1-4.2,3.3-5.5c12-6.8,24-13.8,36-20.8c0.4-0.2,0.8-0.4,1.3-0.6c2.2-1,2.7-0.8,2.6,1.6 c-0.1,7-0.2,14-0.3,21c-0.2,11.1-0.6,22.2-0.7,33.3c-0.1,11.7,0,23.5,0,35.2C230,228.8,229.8,228.8,229.6,228.8z"
      />
      <path
        id="vue1"
        style="visibility: hidden"
        d="M250,412c-42.1-59.9-83.8-119.2-125.7-178.8c4-2.3,7.8-4.6,11.7-6.8c10.7-6.2,21.4-12.4,32-18.6 c1.6-0.9,3.3-1.8,4.9-2.8c1.2-0.8,1.8-0.6,2.6,0.6c5.3,7.6,10.6,15.2,16,22.8c12.2,17.4,24.4,34.8,36.6,52.2 c6.8,9.7,13.7,19.4,20.6,29.1c0.3,0.4,0.6,0.7,1.2,1.5c1.2-2.9,2.3-5.6,3.3-8.2c8-20.6,15.9-41.2,23.9-61.8 c8.7-22.4,17.5-44.8,26.2-67.2c6.5-16.9,13.1-33.7,19.6-50.6c0.7-1.8,1.3-3.6,2.1-5.3c0.4-0.8,1.1-1.5,1.9-2 c12.6-7.3,25.2-14.6,37.8-21.9c3.6-2.1,7.2-4.2,11.1-6.4C333.8,196,292,303.8,250,412z"
      />
      <path
        id="vue2"
        style="visibility: hidden"
        d="M324,107.1c-25.1,64.9-50.1,129.2-75.3,194.3c-25.3-36-50.3-71.5-75.4-107.3c4.1-2.4,8.1-4.7,12-7 c10.9-6.3,21.8-12.6,32.6-18.9c1.7-1,2.6-0.8,3.6,0.7c6.8,9.7,13.8,19.4,20.6,29.1c2.1,2.9,4.1,5.9,6.5,9.4 c1.3-3.2,2.4-6,3.5-8.8c8-20.7,15.9-41.4,23.8-62.1c1.1-3,3.8-3.9,6.1-5.2c13.4-7.8,26.9-15.6,40.4-23.4 C322.8,107.6,323.1,107.5,324,107.1z"
      />
      <path
        id="svelte1"
        style="visibility: hidden"
        d="M347.1,186.4c-6.6-5.4-13.1-10.9-20.5-14.6c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.3,0.4c-8.4-2.6-17-2.4-25.9-0.2 c0-1.7,0.1-3.1-0.4-4.5c-0.4-7.3-6.2-10.3-12.6-6.6c-4.3,2.6-7.2,6.4-10.1,10.3c-10.9,14.4-21.9,28.8-32.9,43.3 c-6.8,9.1-13.7,18.1-20.4,27.3c-3,4-4.2,8.7-3.6,13.8c0.7,5.8,5.2,8.4,10.7,6.2c4.4-1.7,7.4-5,10.1-8.6 c7.5-9.8,14.8-19.7,22.5-29.3c7.9-9.8,17.5-17.5,29.7-21.5c8.1-2.6,16-2.1,23.1,3.1c5.2,3.8,7.9,9.3,9.2,15.5 c2,10,0.8,19.8-2.2,29.5c-3.7,12.1-10.2,22.5-17.9,32.4c-7.9,10.2-15.7,20.6-23.6,30.9c-10.2,13.4-20.3,27-30.8,40.2 c-7.4,9.5-16.7,16.8-28.2,20.8c-6.4,2.2-12.9,3.2-19.5,0.5c-5.5-2.3-9-6.6-11.8-11.7c-2.7-5.1-3.3-10.6-3.3-16.3 c-0.1-6.8,1.1-13.3,2.8-19.9c8.7,2.7,17.4,2.5,26.3,0.3c0,1.8-0.1,3.3,0,4.8c0.3,6.9,6,10.2,12.7,6.5c3.9-2.1,6.6-5.5,9.2-8.9 c10.3-13.5,20.5-27,30.8-40.5c7.7-10.2,15.5-20.4,23.1-30.7c2.8-3.9,4.3-8.4,4.1-13.3c-0.4-6.9-5.4-9.9-11.7-7 c-3.9,1.8-6.7,4.8-9.2,8.1c-7.4,9.7-14.5,19.5-22.1,28.9c-8.3,10.2-18.3,18.2-31.1,22.1c-7.7,2.3-15.2,1.7-21.9-3.1 c-10.4,6.2-21,12-30.4,19.6c0.2,0.8,0.1,1.5-0.2,2.3c-6.4,15.1-10.2,30.7-9.8,47.1c0.2,8.9,1.5,17.7,5.4,25.8 c6.1,12.7,16.2,19.9,30.2,21.4c9,1,17.5-1,25.8-4.4c15.4-6.3,28.3-16.1,39-28.6c9.4-10.9,18-22.5,26.8-33.9 c11.6-15.1,23.1-30.4,34.7-45.5c8.6-11.2,15.9-23.2,21.4-36.3c6.2-14.9,10.1-30.3,9.9-46.5C354,205.6,352.5,195.6,347.1,186.4 C347.1,186.5,347.1,186.4,347.1,186.4z"
      />
      <path
        id="svelte2"
        style="visibility: hidden"
        d="M201.3,294.9c-5.8-4.1-8.4-10.3-9.6-17.1c-1.9-10.8-0.1-21.2,3.5-31.4c3.8-10.9,10-20.4,17-29.4 c9-11.6,17.9-23.4,26.8-35.1c9.1-11.9,18-24,27.3-35.7c7.8-9.8,17.4-17.6,29.6-21.6c6-2,12.1-2.3,18.1,0.1 c7,2.7,11.3,7.9,13.5,14.8c3.5,10.9,2.1,21.6-0.9,32.3c7.4,3.8,13.9,9.2,20.5,14.6c-0.3-0.7,0-2,0.3-2.8c2-6.3,4.4-12.5,6.2-18.8 c2-7.1,3-14.4,3.3-21.8c0-0.6,0.2-1.1,0.3-1.6c0-2.2,0-4.5,0-6.7c-0.4-2.9-0.6-5.9-1.1-8.8c-2-12.9-7.3-23.9-18.6-31.2 c-4.8-3.1-10.2-4.9-15.9-5.4c-0.6-0.1-1.2-0.3-1.8-0.4c-1.4,0-2.7,0-4.1,0c-3.4,0.5-6.9,0.9-10.2,1.6c-11.9,2.8-22.3,8.7-32,16 c-11.6,8.7-21,19.6-29.6,31.2c-8.8,11.8-17.9,23.5-26.8,35.3c-10.5,14.1-21.5,27.9-31.4,42.5c-11.4,16.8-18.4,35.4-21.1,55.6 c-1.4,10.5-1.5,21,1.4,31.2c1.1,4,2.9,7.7,4.6,11.4c0.2,0.3,0.3,0.6,0.3,0.8C180.3,306.9,190.9,301.1,201.3,294.9 C201.3,294.9,201.3,294.9,201.3,294.9z"
      />
    </svg>
  </div>
</template>

<script>
import KUTE from 'kute.js'

export default {
  data () {
    return {
      currentTechno: 0,
      interval: null
    }
  },
  props: {
    svg: String
  },
  mounted () {
    document.body.addEventListener('mousemove', (event) => {
      const x = event.clientX / 1600
      const y = event.clientY / 950
      this.$refs.svg.style.marginLeft = `${(1 - x) * 10}px`
      this.$refs.svg.style.marginTop = `${(1 - y) * 10}px`
    })
  },
  methods: {
    transitionSVG (newSVG) {
      KUTE.to('#original1', { path: `#${newSVG}1` }).start()
      KUTE.to('#original2', { path: `#${newSVG}2` }).start()
    }
  },
  watch: {
    svg (newVal) {
      this.transitionSVG(newVal)
    }
  }
}
</script>

<style scoped lang="scss">
.cuve {
  position: relative;

  img {
    height: 855px;

    @media only screen and (max-width: 1325px){
      height: 413px;
    }
  }

  svg {
    position: absolute;
    top: 62%;
    left: 60%;
    width: 50%;
    transform: translate(-50%, -50%);
    animation: rotateAnimation 3s linear infinite;
  }
}

@keyframes rotateAnimation {
  0% {
    transform: translate(-50%, -50%) translatey(10px);
  }
  50% {
    transform: translate(-50%, -50%) translatey(-10px);
  }
  100% {
    transform: translate(-50%, -50%) translatey(10px);
  }
}
</style>
