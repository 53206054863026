<template>
  <div class="paper-container">
    <div class="main-container border">
      <slot/>
    </div>
    <div class="layer1 border" />
    <div class="layer2 border" />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.paper-container{
  position: relative;
  border: solid 1px white;

  .border{
    border: solid 2px;
    box-sizing: border-box;
  }

  .main-container{
    position: relative;
    z-index: 3;
    background: white;
    margin: 0px 0px 20px 20px;
  }

  .layer1{
    position: absolute;
    z-index: 2;
    background: white;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    top: 10px;
    left: 10px;
  }

  .layer2{
    position: absolute;
    z-index: 1;
    background: white;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    top: 20px;
    left: 0px;
  }

}
</style>
