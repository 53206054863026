<template>
    <div class="ball-container">
      <div class="circle"/>
      <img src="../assets/blockBall/support.svg" alt="" class="support">
      <img src="../assets/blockBall/dottedCircle.svg" alt="" class="dotted-circle">
      <div id="game-container" class="wrapper"/>
    </div>
</template>

<script>
import { Engine, Render, Bodies, Runner, Common, Composite, Body, Mouse, MouseConstraint } from 'matter-js'
import { getHalfCirclePoints } from '../services/matter-helper'

export default {
  mounted () {
    this.loadBallpit()
  },
  data () {
    return {
      empty: true
    }
  },
  props: {
    projects: Array
  },
  methods: {
    preloadImage (url, callback) {
      var img = new Image()
      img.src = url
      img.onload = callback
      img.onerror = (error) => console.log('error', error)
    },
    loadBallpit () {
      Common.setDecomp(require('poly-decomp'))

      const engine = Engine.create()
      const world = engine.world
      engine.world.gravity.y = 2

      const render = Render.create({
        element: document.getElementById('game-container'),
        engine: engine,
        options: {
          width: 778,
          height: 778,
          wireframes: false,
          background: 'transparent'
        }
      })

      const path1 = getHalfCirclePoints(389, 100, 20)
      const path2 = getHalfCirclePoints(389, 100, 20, false)
      const path3 = getHalfCirclePoints(70, 5, 10, true, Math.PI / 5, Math.PI - Math.PI / 5)

      const half1 = Bodies.fromVertices(387, 670, path1, { isStatic: true, render: { fillStyle: 'transparent' } }, true)
      const half2 = Bodies.fromVertices(387, 109, path2, { isStatic: true, render: { fillStyle: 'transparent' } }, true)

      const pedestal = Bodies.fromVertices(380, 281, path3, { isStatic: true, render: { fillStyle: 'transparent' } }, true)
      const pusher1 = Bodies.rectangle(390, 40, 10, 50, { isStatic: true, rotationSpeed: 0.01, render: { fillStyle: '#ffff', lineWidth: 2, strokeStyle: 'black' } })
      const pusher2 = Bodies.rectangle(390, 778, 10, 50, { isStatic: true, rotationSpeed: 0.01, render: { fillStyle: '#ffff', lineWidth: 2, strokeStyle: 'black' } })
      const pusher3 = Bodies.rectangle(390, 778, 10, 50, { isStatic: true, rotationSpeed: 0.01, render: { fillStyle: '#ffff', lineWidth: 2, strokeStyle: 'black' } })

      Composite.add(world, [half1, half2, pedestal, pusher1, pusher2, pusher3])
      Body.setAngle(pusher1, 0)
      Body.setAngle(pusher2, (2 * Math.PI) / 3)
      Body.setAngle(pusher3, 2 * (2 * Math.PI) / 3)

      const speed = 0.005
      const radius = 360

      const updateRotation = () => {
        Body.setAngle(pusher1, pusher1.angle + speed)
        Body.setAngle(pusher2, pusher2.angle + speed)
        Body.setAngle(pusher3, pusher3.angle + speed)

        const angleOffset1 = Math.PI / 2
        const angleOffset2 = Math.PI / 2
        const angleOffset3 = Math.PI / 2

        Body.set(pusher1, 'position', { x: Math.cos(pusher1.angle + angleOffset1) * radius + 390, y: Math.sin(pusher1.angle + angleOffset1) * radius + 390 })
        Body.set(pusher2, 'position', { x: Math.cos(pusher2.angle + angleOffset2) * radius + 390, y: Math.sin(pusher2.angle + angleOffset2) * radius + 390 })
        Body.set(pusher3, 'position', { x: Math.cos(pusher3.angle + angleOffset3) * radius + 390, y: Math.sin(pusher3.angle + angleOffset3) * radius + 390 })
        requestAnimationFrame(updateRotation)
      }
      window.requestAnimationFrame(updateRotation)

      const balls = []

      for (let index = 0; index < this.projects?.length; index++) {
        var circle = Bodies.circle(300, 300, 67, {
          render: {
            fillStyle: '#ffff',
            lineWidth: 2,
            strokeStyle: 'black'
          }
        })
        balls.push(circle)
        Composite.add(world, circle)
      }

      let oldIndex = -1

      setInterval(() => {
        let projectIndex = null
        balls.forEach((ball, index) => {
          if (Math.abs(ball.position.x - 379) < 20 && Math.abs(ball.position.y - 221) < 20) {
            projectIndex = index
          }
        })

        if (projectIndex != null) {
          if (this.empty || oldIndex !== projectIndex) {
            this.empty = false
            balls[projectIndex].render.fillStyle = 'black'
            this.$emit('project', this.projects[projectIndex].name)
          }
          oldIndex = projectIndex
        } else if (!this.empty) {
          this.empty = true
          this.$emit('empty')
        }
      }, 200)

      var mouse = Mouse.create(render.canvas)
      var mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.02,
          render: {
            visible: false
          }
        }
      })

      mouseConstraint.mouse.element.removeEventListener('mousewheel', mouseConstraint.mouse.mousewheel)
      mouseConstraint.mouse.element.removeEventListener('DOMMouseScroll', mouseConstraint.mouse.mousewheel)

      Composite.add(world, mouseConstraint)

      // keep the mouse in sync with rendering
      render.mouse = mouse

      // wrapping using matter-wrap plugin
      var allBodies = Composite.allBodies(world)

      for (var i = 0; i < allBodies.length; i += 1) {
        allBodies[i].plugin.wrap = {
          min: { x: render.bounds.min.x - 100, y: render.bounds.min.y },
          max: { x: render.bounds.max.x + 100, y: render.bounds.max.y }
        }
      }

      // run the renderer
      Render.run(render)
      // create runner
      var runner = Runner.create()
      // run the engine
      Runner.run(runner, engine)

      const canvas = document.querySelector('canvas')
      canvas.style.width = '100%'
    }
  }

}
</script>

<style lang="scss" scoped>

  .ball-container{
    aspect-ratio: 1 / 1;
    width : 100%;
    max-width: 778px;
    position: relative;

    >.wrapper{
      height : 100%;
      width : 100%;
      position: relative;
      z-index: 2;
    }

    >.circle{
      aspect-ratio: 1 / 1;
      position: absolute;
      width: 100%;
      border-radius: 50%;
      border: solid 2px;
      box-sizing: border-box;
    }

    >.support{
      position: absolute;
      right: 31%;
      top: 20%;
      width: 40%;
    }

    .dotted-circle{
      width: 16%;
      position: absolute;
      right: 43%;
      top: 20%;
    }
  }

</style>
