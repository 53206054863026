<template>
  <div ref="mainScene" class="mainScene">
    <img v-for="(img , index) of imageAnimationConfigs"
      :key="index"
      :ref="`scene${index}`"
      @load="()=>setImageLoadingState(index)"
      :style="{ width: (img.width / 1440 * 100) + '%', top: img.top / 789 * 100 + '%'  , left: (img.left / 1440 * 100) + '%'}"
      :src="require( `../assets/landing/${img.src}`)">
  </div>
</template>

<script>

export default {
  name: 'MainScene',
  data () {
    return {
      isLoading: true,
      threshold: 2000,
      progressValue: 0,
      scrollValue: 0,
      imageAnimationConfigs: [
        { left: 84, top: -153, width: 310, direction: 3, range: 0.3, src: 'escalier_marche_3.svg' },
        { left: -1, top: -49, width: 313, direction: 3, range: 0.3, src: 'escalier_marche_2.svg' },
        { left: 611, top: 173, width: 139, direction: 3, range: 0.3, src: 'tuyau_3.svg' },
        { left: 556, top: -64, width: 922, direction: 1, range: 0.3, src: 'tuyau_j.svg' },
        { left: 707, top: 385, width: 141, direction: 3, range: 0.3, src: 'tuyau_o.svg' },
        { left: 1024, top: 299, width: 831, direction: 2, range: 0.3, src: 'tuyau_2.svg' },
        { left: 818, top: 70, width: 820, direction: 4, range: 0.2, src: 'tuyau_u.svg' },
        { left: 913, top: 498, width: 138, direction: 0, range: 0.3, src: 'tuyau_l.svg' },
        { left: 253, top: -256, width: 821, direction: 1, range: 0.3, src: 'tuyau_e.svg' },
        { left: 500, top: -346, width: 814, direction: 1, range: 0.3, src: 'tuyau_4.svg' },
        { left: 736, top: -225, width: 288, direction: 3, range: 0.3, src: 'tuyau_6.svg' },
        { left: 798, top: -149, width: 820, direction: 2, range: 0.3, src: 'tuyau_5.svg' },
        { left: -84, top: 45, width: 313, direction: 3, range: 0, src: 'escalier_marche_1.svg' },
        { left: 374, top: 174, width: 141, direction: 0, range: 0.3, src: 'tuyau_a.svg' },
        { left: 513, top: 602, width: 392, direction: -1, range: 0, src: 'carton.svg' },
        { left: -256, top: 154, width: 1010, direction: -1, range: 0, src: 'tapis_roulant.svg' },
        { left: -170, top: 361, width: 316, direction: 3, range: 0.3, src: 'escalier_marche_0.svg' },
        { left: 144, top: 311, width: 122, direction: 2, range: 0.3, src: 'boule.svg' },
        { left: 311, top: 236, width: 316, direction: -1, range: 0, src: 'machine.svg' },
        { left: 155, top: 570, width: 138, direction: 0, range: 0.3, src: 'tuyau_1.svg' },
        { left: -347, top: 1053, width: 813, direction: 4, range: 0.3, src: 'tuyau_7.svg' },
        { left: 1009, top: 567, width: 832, direction: 2, range: 0.3, src: 'tuyau_i.svg' },
        { left: 852, top: 388, width: 825, direction: 1, range: 0.1, src: 'tuyau_a_2.svg' }
      ]
    }
  },
  methods: {
    setImageLoadingState (index) {
      let allLoaded = true
      this.imageAnimationConfigs[index].loaded = true

      this.imageAnimationConfigs.forEach(imageConfig => {
        if (!imageConfig.loaded) allLoaded = false
      })

      if (allLoaded) {
        this.isLoading = false
        this.setupScrollDetection()
      }
    },
    setupScrollDetection () {
      this.threshold = this.$refs.mainScene.clientHeight

      window.addEventListener('scroll', () => {
        this.scrollValue = Math.min(window.scrollY / this.threshold, 1)
        requestAnimationFrame(this.moveImages)
      })
    },
    moveImages () {
      for (let index = 0; index <= 22; index++) {
        const completeMovement = this.$refs.mainScene.clientHeight * this.imageAnimationConfigs[index].range
        const angle = Math.PI / 3 * this.imageAnimationConfigs[index].direction - Math.PI / 2

        this.$refs[`scene${index}`][0].style.marginTop = `${Math.sin(angle) * (this.scrollValue) * completeMovement}px`
        this.$refs[`scene${index}`][0].style.marginLeft = `${Math.cos(angle) * (this.scrollValue) * completeMovement}px`
      }
    }

  }
}
</script>

<style scoped lang="scss">
.mainScene {
  width: 100%;
  aspect-ratio: 1440 / 789;
  position: relative;

  img{
    position: absolute;
  }

  >.svg{
    &_0{
      top: 42.48%;
      left: 60.41%;
      width: 65.625%;
    }
    &_1{
      top: -39.01%;
      left: 76.25%;
      width: 65.625%;
    }
    &_2{
      top: -25.43%;
      left: 51.04%;
      width: 23.125%;
    }
    &_3{
      top: -22.15%;
      left: 49.09%;
      width: 65.625%;
    }
    &_4{
      top: -39.01%;
      left: 32.5%;
      width: 65.625%;
    }
    &_5{
      top: 25.81%;
      left: 17.36%;
      width: 25.138%;
    }
    &_6{
      top: 34.39%;
      left: 4.305%;
      width: 9.16%;
    }
    &_7{
      top: 60.59%;
      left: 2.152%;
      width: 11.041%;
    }
    &_8{
      top: 16.57%;
      left: -27.98%;
      width: 80.69%;
    }
    &_9{
      top: 62.23%;
      left: 72.91%;
      width: 65.694%;
    }
    &_10{
      top: 66.85%;
      left: 33.61%;
      width: 30.83%;
    }
    &_11{
      top: 54.62%;
      left: 65.20%;
      width: 11.11%;
    }
    &_12{
      top: 7.225%;
      left: 57.70%;
      width: 65.625%;
    }
    &_13{
      top: 42.29%;
      left: 49.02%;
      width: 11.11%;
    }
    &_14{
      top: 32.85%;
      left: 73.95%;
      width: 65.625%;
    }
    &_15{
      top: 19.07%;
      left: 21.73%;
      width: 11.11%;
    }
    &_16{
      top: -1.156%;
      left: 36.87%;
      width: 65.625%;
    }
    &_17{
      top: 18.78%;
      left: 41.38%;
      width: 11.11%;
    }
    &_18{
      top: -28.51%;
      left: 12.84%;
      width: 65.625%;
    }
    &_19{
      top: 4.720%;
      left: -13.95%;
      width: 25%;
    }
    &_20{
      top: -10.21%;
      left: -7.36%;
      width: 25%;
    }
    &_21{
      top: -17.91%;
      left: 0.76%;
      width: 25%;
    }
  }
}
</style>
