<template>
  <section class="main">
    <div class="title-block">
      <div class="title-wrapper">
        <h2>
          I worked on some fun <br />
          personal projects...
        </h2>
      </div>
    </div>
    <div class="screen-block">
      <Screen :project="currentProject" />
    </div>
    <div class="ball-block">
      <BallPit
        class="ball-pit"
        :projects="projects"
        @empty="() => setProject(null)"
        @project="setProject"
      />
    </div>
  </section>
</template>

<script>
import BallPit from './BallWashing.vue'
import Screen from './TV.vue'

export default {
  components: {
    BallPit,
    Screen
  },
  methods: {
    setProject (val) {
      this.project = val
    }
  },
  computed: {
    currentProject () {
      const object = this.projects.find((p) => p.name === this.project)

      return object
    }
  },
  mounted () {
    setInterval(() => {
      this.state = !this.state
    }, 3000)
  },
  data () {
    return {
      projects: [
        {
          name: 'moist_chameleon',
          image: 'moist.png',
          title: 'Moist Chameleon',
          url: 'https://www.moistchameleon.com/',
          description:
            'Moist Chameleon was made in collaboration with a graphist. It’s an idle game where you need to collect, combine and play cards.'
        },
        {
          name: 'burger_master',
          image: 'burgermaster.png',
          title: 'Burger Master',
          url: 'https://jeanjouliacode.github.io/BurgerMaster/',
          description:
            'My first browser game.It was made with no javascript framework. The goal is to make burgers as fast as possible and increase profit.'
        },
        {
          name: 'random_wheel',
          image: 'spinningwheel.png',
          title: 'Random Wheel',
          url: 'https://jeanjouliacode.github.io/randomizer/#/',
          description:
            'If you ever need to choose between multiple options at random, this is the website you need. It uses random numbers generated from atmospheric noise to spin the wheel'
        },
        {
          name: 'though_drink',
          image: 'toughdrink.png',
          title: 'Though Drink',
          url: 'https://jeanjouliacode.github.io/ToughDrink/',
          description:
            'A  game in which you battle against your friends in an imaginary drinking game.'
        },
        {
          name: 'wipe_clean',
          image: 'wipeclean.gif',
          title: 'WipeClean',
          url: 'https://www.npmjs.com/package/wipeclean',
          description:
            'WipeClean is a command-line tool designed to clean your terminal in a satisfying way. its squeegee will leave your terminal squeaky clean.'
        },
        {
          name: 'flower_generator',
          image: 'flowersvg.png',
          title: 'Flower generator',
          url: 'https://jeanjouliacode.github.io/proceduralflower/',
          description:
            'This website lets you generate svg files of procedural flowers. It also comes with a mini-game.'
        },
        {
          name: 'screwball',
          image: 'screwball.jpg',
          title: 'Screwball scramble',
          url: 'https://screwballscramble.net/',
          description:
            'A web version of the game screwball scramble. It was made with Svelte and threejs.'
        },
        {
          name: 'melissaProtfolio',
          image: 'melissaPortfolio.png',
          title: 'Mélissa ledoux Portfolio',
          url: 'https://melissaledoux.fr/',
          description:
            'A portfolio website made for a graphist. It was made with Vuejs.'
        }
      ],
      project: null
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  height: 810px;
  margin-bottom: 135px;
  margin-top: 83px;
  overflow-x: hidden;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @media only screen and (max-width: 1325px) {
    height: auto;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-row-gap: 32px;
  }

  .title-wrapper {
    margin: 0px 0px 48px 120px;
    text-align: start;
    width: 570px;

    @media only screen and (max-width: 1325px) {
      margin: 0px;
      text-align: center;
      width: 100%;
    }
  }

  .cable {
    position: absolute;
    right: 100px;
    top: 50px;
  }

  .title-block {
    grid-area: 1 / 1 / 2 / 3;
  }
  .screen-block {
    grid-area: 2 / 1 / 6 / 3;
  }
  .ball-block {
    grid-area: 1 / 3 / 6 / 5;
    display: flex;
    overflow: hidden;
    justify-content: flex-end;
    margin-right: -150px;

    @media only screen and (min-width: 1800px) {
      margin-right: 150px;
    }
  }

  @media only screen and (max-width: 1325px) {
    .title-block {
      grid-area: 1 / 1 / 2 / 2;
    }
    .screen-block {
      grid-area: 4 / 1 / 7 / 2;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ball-block {
      grid-area: 2 / 1 / 4 / 2;
      display: flex;
      justify-content: center;
      margin-right: 0px;
      overflow: visible;
    }

    .ball-pit {
      width: 70%;
    }
  }
}
</style>
