export function getHalfCirclePoints (radius, strokeWidth, pointNumber, orientation = true, start = 0, end = Math.PI) {
  const firstHalf = []
  const secondHalf = []
  const orientationVal = orientation ? 1 : -1

  const step = (end - start) / pointNumber

  for (let index = 0; index <= pointNumber; index++) {
    firstHalf.push({ x: Math.cos(step * index + start) * radius, y: orientationVal * Math.sin(step * index + start) * radius })
    secondHalf.push({ x: Math.cos(step * index + start) * (radius + strokeWidth), y: orientationVal * Math.sin(step * index + start) * (radius + strokeWidth) })
  }

  return [...firstHalf, ...secondHalf.reverse()]
}
