<template>
  <PaperContainer class="wrapper">
    <div class="screen">
      <div v-if="project">
        <h3>{{ project.title }}</h3>
        <br />
        <p>
          {{ project.description }}
        </p>
        <br />
        <div class="image-wrapper">
          <img :src="getImgUrl" />
        </div>

        <div class="button-container">
          <a :href="project.url" target="_blank">
            <MyButton>SEE THE WEBSITE</MyButton>
          </a>
        </div>
      </div>
      <div class="empty" v-else>
        <img src="../assets/blockBall/tuto.svg" alt="" />
        <span>DRAG AND DROP A BALL <br />ON THE PEDESTAL</span>
      </div>
    </div>
  </PaperContainer>
</template>

<script>
import PaperContainer from './PaperContainer.vue'
import MyButton from './Button.vue'

export default {
  components: {
    PaperContainer,
    MyButton
  },
  props: {
    project: Object
  },
  computed: {
    getImgUrl () {
      return require('../assets/blockBall/capture/' + this.project.image)
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 575px;
  margin-left: 100px;
  max-width: 575px;

  .image-wrapper{
    aspect-ratio: 280 / 100;
  }

  @media only screen and (max-width: 1330px) {
    width: 90%;
    margin-left: 0px;
  }

  .screen {
    padding: 22px 39px 0px 29px;

    .empty {
      width: 100%;
      height: 500px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: "Poppins", sans-serif;
      font-style: italic;
      font-weight: 600;
      font-size: 18px;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin: 23px 0 23px 0;

    a {
      color: black;
      text-decoration: none;
    }
  }
}

img {
  margin-top: 17px;
  width: 100%;
}
</style>
