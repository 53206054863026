<template>
<div class="home">
  <div class="landing_wrapper">
    <landing/>
  </div>
  <div class="gradient"/>
  <div v-animate-onscroll="'animated fadeInDown'" class="title-wrapper">
    <h1>creative web developer <br/> currently based in dublin.</h1>
  </div>
  <img src="../assets/separator.svg" alt="" class="separator">
  <BlockBalls/>
  <BlockCuve/>
  <BlockSeeMore/>
</div>

</template>

<script>
import Landing from '../components/Landing.vue'
import BlockCuve from '../components/BlockCuve.vue'
import BlockBalls from '../components/BlockBalls.vue'
import BlockSeeMore from '../components/BlockSeeMore.vue'

export default {
  name: 'Home',
  methods: {
    test () {
      console.log('test')
    }
  },
  components: {
    Landing,
    BlockCuve,
    BlockBalls,
    BlockSeeMore
  }
}
</script>

<style scoped lang="scss">
h1 {
  text-align: center;
}

.home {
  position: relative;

  > .title-wrapper{
    position: relative;
    z-index: 3;
  }

  >.landing_wrapper{
    padding-bottom: 10%;
    overflow: hidden;
  }

  > .separator{
    width: 100%;

    @media only screen and (min-width: 1330px){
      display:none ;
    }
  }
}

.gradient {
  width: 100%;
  height: 13.82vw;
  margin-top: -13.82vw;
  z-index: 2;
  position: absolute;
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.7063200280112045) 51%, rgba(0,0,0,0) 100%);
}

</style>
