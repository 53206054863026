<template>
  <button @click="click" @tap="click">
    <div class="top-square" ref="topSquare">
      <slot></slot>
      <img src="../assets/arrow.svg" alt="" @load="loaded"/>
    </div>
    <div class="bottom-square" ref="bottomSquare"/>
  </button>
</template>

<script>
export default {
  methods: {
    loaded () {
      const width = this.$refs.topSquare.getBoundingClientRect().width
      this.$refs.bottomSquare.style.width = `${width}px`
    },
    click () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
$offset: 10px;

button {
  all: unset;
  position: relative;
  height: 58px;
  display: flex;
  cursor: pointer;
  white-space: nowrap;

  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  font-family: "Poppins", sans-serif;
  font-style: italic;

  .top-square {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;

    margin-left: $offset;
    padding: 0px 28px;
    height: 48px;

    box-sizing: border-box;
    border: solid 2px;

    background: white;

    img {
      margin-left: 15px;
    }
  }

  .top-square:hover {
    transition: margin 0.1s;
    margin: 5px 0px 0px 5px !important;
  }

  .bottom-square {
    position: absolute;
    bottom: 0px;
    background: black;
    width: calc(100% - #{$offset});
    height: 48px;
    top: $offset;
  }

  .top-square:active{

    transition: margin 0.1s;
    margin: 10px 0px 0px 0px !important;
  }
}
</style>
