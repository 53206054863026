<template>
  <div class="big-button" :style="scssVars">
    <div
      ref="topCircle"
      class="top-circle"
      :class="{ 'topCircle--hover': state === 'hover' , 'topCircle--pressed': state === 'pressed' }"
      @click="() => setClassState('pressed')"
      @tap="() => setClassState('pressed')"
      @mouseover="() => setClassState('hover')"
      @mouseleave="() => setClassState(null)"
    >
      <span>PRESS ME</span>
    </div>
    <div class="line-wrapper">
      <div
        ref="line"
        class="line"
        :class="{ 'line--hover': state === 'hover', 'line--pressed': state === 'pressed' }"
      />
    </div>
    <div class="bottonCircle" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      state: null
    }
  },
  methods: {
    setClassState (state) {
      switch (state) {
        case 'hover':
          this.state = 'hover'
          break
        case 'pressed':
          this.openMail()
          this.state = 'pressed'
          setTimeout(() => { this.state = 'nothing' }, 200)
          break
        default:
          this.state = 'nothing'
      }
    },
    openMail () {
      window.location.href = 'mailto:jean.joulia.france@gmail.com?subject=Hello Jean Joulia'
    }
  },
  computed: {
    detectMob () {
      return window.innerWidth <= 1330
    },
    scssVars () {
      let diameter = 300
      let offset = 60

      if (this.detectMob) {
        diameter = 172
        offset = 36
      }

      console.log(Math.sqrt(2 * Math.pow(offset, 2)))

      return {
        '--circle-diameter': diameter + 'px',
        '--circle-offset': offset + 'px',
        '--line-width': Math.sqrt(2 * Math.pow(offset, 2)) + 'px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.big-button {
  width: calc(var(--circle-diameter) + var(--circle-offset));
  height: calc(var(--circle-diameter) + var(--circle-offset));
  position: relative;
  overflow: hidden;

  .top-circle {
    box-sizing: border-box;
    width: var(--circle-diameter);
    height: var(--circle-diameter);
    border: solid 1px white;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 0px;
    margin-left: var(--circle-offset);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
  }

  .topCircle {
    &--hover{
      transition: all 0.1s;
      margin-top: calc(var(--circle-offset)/6);
      margin-left: calc(var(--circle-offset)/6*5);
    }

    &--pressed{
      margin-top: calc(var(--circle-offset)/6*5);
      margin-left: calc(var(--circle-offset)/6);
    }

  }

  > .line-wrapper {
    width: var(--circle-diameter);
    height: var(--circle-diameter);
    transform: rotate(-45deg);
    z-index: 2;

    position: absolute;
    top: calc(var(--circle-offset)/2);
    left: calc(var(--circle-offset)/2);

    > .line {
      box-sizing: border-box;
      width: var(--line-width);
      height: var(--circle-diameter);
      margin-left: calc((var(--circle-diameter) - var(--line-width))/2);
      border-top: solid 2px whitesmoke;
      border-bottom: solid 2px whitesmoke;
      background: black;
    }

    > .line {
      &--hover {
        transition: all 0.1s;
        width: calc(var(--circle-offset)/6*5);
      }
      &--pressed {
        width: calc(var(--circle-offset) /6 );
      }
    }
  }

  .bottonCircle {
    box-sizing: border-box;
    width: var(--circle-diameter);
    height: var(--circle-diameter);
    border: solid 2px white;
    border-radius: 50%;
    position: absolute;
    top: var(--circle-offset);
    left: 0px;
  }
}
</style>
