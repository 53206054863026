<template>
  <section>
    <div class="cuve">
      <Cuve ref="cuve" :svg="texts[currentTechno].svgID"/>
    </div>
    <div class="title">
      <h2 v-animate-onscroll="'animated fadeInUp'">AND DEVELOPED SOME</h2>
      <h2 v-animate-onscroll="'animated fadeInUp'">SKILLS OVER THE YEARS</h2>
    </div>
    <div class="content">
      <div class="info">
        <div class="techno">
          <Transition name="slide-fade">
            <h3 v-if="show">{{texts[currentTechno].title}}</h3>
          </Transition>
        </div>
        <div class="p-wrapper">
          <Transition name="slide-fade">
            <p v-if="show">
              {{texts[currentTechno].content}}
            </p>
          </Transition>
        </div>

        <MyButton class="button-next" @click="next">NEXT</MyButton>
      </div>
    </div>
    <div class="panel">
      <img src="../assets/blockCuve/boutons.svg" alt="" class="boutons" />
    </div>
  </section>
</template>

<script>
import MyButton from './Button.vue'
import Cuve from './VAT.vue'

export default {
  components: {
    MyButton,
    Cuve
  },
  data () {
    return {
      currentTechno: 0,
      texts: [
        { svgID: 'vue', title: 'Vue', content: 'A powerful javascript framework for frontend development. It\'s my framework of choice for creating awesome websites and progressive web apps.' },
        { svgID: 'flutter', title: 'Flutter', content: 'FLutter is an amazing hybrid framework for mobile development. it uses Dart for programming and is filled with small life quality improvements that make developing on it very pleasant.' },
        { svgID: 'svelte', title: 'Svelte', content: 'A trending framework for web developments. It takes everything I loved about Vue js and makes it even more accessible and easy to use. its documentation is great and it has amazing performances.' },
        { svgID: 'js', title: 'Javascript', content: 'Many hate it but I love Javascript. It\'s easy to pick up and run in the browser. with it you can build webapp, mobile app, desktop app, event machine learning and it\'s still being updated to this day.' }
      ],
      show: true
    }
  },
  methods: {
    next () {
      this.show = false
      this.currentTechno = (this.currentTechno + 1) % this.texts.length
      setTimeout(() => { this.show = true }, 300)
    }
  }
}
</script>

<style scoped lang="scss">
section {
  overflow: hidden;

  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .boutons {
    height: 456px;

    @media only screen and (max-width: 1325px){
      height: 213px;
    }
  }

  .cuve{
    grid-area: 1 / 1 / 3 / 4;
     display: flex;
     justify-content: flex-start;
     align-items: center;
     margin-right: 80px;
  }

  .title{
    grid-area: 1 / 4 / 2 / 8;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: start;
  }

  .panel{
     grid-area: 1 / 8 / 3 / 10;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: flex-end;
  }

  .content {
    grid-area: 2 / 4 / 3 / 8;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    text-align: left;

    > .info{
      max-width: 620px;
      width: calc(100% - 64px);
      margin-bottom: 32px;
      margin-top: 82px;

      .p-wrapper{
        height: 113px;
      }

      > p {
        white-space: pre-wrap;
      }

      .button-next{
        margin-top: 32px;
      }
    }

    > .techno{
      margin-bottom: 14px;
    }

    h2 {
      font-family: "Soulcraft";
    }

    h3 {
      font-family: "Soulcraft";
      font-weight: 400;
      font-size: 42px;
      line-height: 58px;
    }
  }

  @media only screen and (max-width: 1325px){
    .cuve{
      grid-area: 2 / 1 / 3 / 2;
      margin-right: 0px;
    }

    .title{
      grid-area: 1 / 1 / 2 / 3;
      text-align: center;
    }

    .panel{
      grid-area: 2 / 2 / 3 / 3;
    }

    .content{
      grid-area: 3 / 1 / 4 / 3;
      max-width: none;
      display: flex;
      justify-content: center;
      align-items: center;

      .info{
        margin-top: 42px;

        .p-wrapper{
          height: 230px;
        }
      }
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
